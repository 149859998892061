import './App.scss';
import { ConfigProvider, App as AntApp, theme } from 'antd';
import RoutesWrapper from './Routes/RoutesWrapper';
import QueryProvider from './services/react-query';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import en from './Localization/en';
import ar from './Localization/ar';
import 'react-phone-number-input/style.css';
function App() {
  const { darkMode, currentLang } = useSelector((state) => state.user);
  // console.log(currentLang);
  const t = currentLang === 'en' ? en : ar;
  const VERSION = '1.0.2';

  useEffect(() => {
    if (currentLang) {
      document
        .getElementsByTagName('html')[0]
        .setAttribute('lang', currentLang);
    }
  }, [currentLang]);
  useEffect(() => {
    checkVersion();
  }, []);

  const checkVersion = () => {
    const cachedVersion = localStorage.getItem('appVersion');
    if (cachedVersion) {
      if (cachedVersion !== VERSION) {
        // Clear the cache if versions don't match
        localStorage.clear();
        // Set the current version before reloading
        localStorage.setItem('appVersion', VERSION);

        // Reload the window
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } else {
      localStorage.setItem('appVersion', VERSION);
    }
  };

  return (
    <QueryProvider>
      <ConfigProvider
        direction={currentLang === 'en' ? 'ltr' : 'rtl'}
        theme={{
          algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
          token: {
            // colorPrimary: ' #0bb197',
            // colorPrimary: darkMode ? '#0bb197' : '#431db1',
            // colorPrimary: darkMode ? '#f5f6f8' : '#0bb197',
            colorPrimary: darkMode ? '#f5f6f8' : '#4020A5',
            // colorPrimary: darkMode ? '#f5f6f8' : '#008ffb',
            // colorPrimary: darkMode ? '#f5f6f8' : '#3ba9fc;',
            colorLinkHover: darkMode ? '#fff' : '#095ecbba', // color link
            colorLink: darkMode ? '#0bb197' : '#095ecb',
            colorBgLayout: darkMode ? '#303841' : '#f5f6f8', // background layout
            colorBgContainer: darkMode ? '#38414a' : '#fff', // background table && card
            contentFontSize: 17,
            fontWeight: 600,
            // colorInfo: '#faad14',
            borderRadius: 8,
            // colorText: '#343a40',
            fontSize: 16,
            controlHeight: 40,
            // colorBtnColor: '#fff',
            // secBtnBg: '#F8F5FF',
            // secColor: '#74788d',
            // defaultBg: '#EEF0FF',
            // defaultColor: '#4F5062',
            // defaultBorderColor: '#B0B0C0',
            // thirdColor: '#59A6ED',
            // Accent: '#EBF2FA',
            // line: '#CFD8E3',
            // grayTxt: '#595959',
            // colorPrimaryBgHover: '#421db192',
            // colorPrimaryHover: '#421db192',
            // colorTextDescription: '#595959',
            // colorPrimaryActive: '#421db192',
            // colorPrimaryText: '#343a40',
            // colorPrimaryTextActive: '#343a40',
            // colorPrimaryTextHover: '#343a40',
            // colorSuccess: '#0ac074',
            // colorSuccessBg: '#E9FFDA',
            // colorWarning: '#ff9d00',
            // colorWarningBg: '#FFF4E2',
            // colorError: '#ff3d60',
            // colorErrorBg: '#FFE7E4',
            // colorTextBase: '#000',
            // colorFillQuaternary: '#ebf2fa',
            // colorFillSecondary: '#ebf2fa',
            // colorPrimaryBg: '#e6fcf9',
            fontSizeXL: 18,
            fontSizeHeading5: 16,
            fontSizeHeading4: 18,
            fontSizeHeading3: 22,
            fontSizeHeading2: 25,
            fontSizeHeading1: 32,
            marginMD: 24,
            wireframe: false,
            marginLG: 32,
            marginXL: 40,
            paddingMD: 24,
            paddingLG: 32,
            paddingXL: 40,
            borderRadiusSM: 4,
            borderRadiusLG: 12,
            borderRadiusXS: 4,
            // colorBgLayout: '#F0F1F2',
            // colorBgLayout: '#f5f6f8',
            // colorBgLayout: '#2f4050',
            // colorBgContainer: '#ffffff',
            // colorBgElevated: '#ffffff',
            // colorPrimaryBorder: '#0bb197',
            // colorPrimaryBorder: '#421db1',
          },
          // components: {
          //   Table: {
          //     stickyScrollBarBg: `background: -webkit-gradient(
          //                           linear,
          //                           left bottom,
          //                           left top,
          //                         )`,
          //     // stickyScrollBarColor: '#fff',
          //     // stickyScrollBarBoxShadow: '0 1px 3px rgba(0, 0, 0, 0.4) inset',
          //     // stickyScrollBarBorderRadius: '4px',
          //     // stickyScrollBarBorder: '1px solid #fff',
          //     stickyScrollBarWidth: '4px',
          //     stickyScrollBarHeight: '4px',
          //   },
          // },
        }}
      >
        <AntApp>
          <RoutesWrapper t={t} />
        </AntApp>
      </ConfigProvider>
    </QueryProvider>
  );
}

export default App;
